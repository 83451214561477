import React from 'react';
import { ModalBody,InputGroup, InputGroupAddon, Modal, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Usuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            empresa:props.usuario.empresa,
            clave: '',
            nombre:'',
            objeto:{},
            objetos: []
        };
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/usuario/lista?id=${this.state.empresa}&filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargar = async (id) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/usuario/cargar/" + id;
        const resp = await fetch(url);
        const objeto = await resp.json();
        objeto.Empresa = this.state.empresa;
         this.setState({
            cargando:false,
            formVisible: true,
            clave: id,
            objeto
        });
    }
    guardar = () =>{
        this.onFormClose();
        this.cargarObjetos();
    }
    nuevo = () => {
        this.cargar(-1);
    }
    imprimir = () =>{
        const url = `${this.props.urlws}/usuario/listaPdf`;
        window.open(url, '', '');
    }
    excel = () =>{
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container style={{ padding: 10 }} >
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <div style={estilo}>
                    <div className="row">
                        <div className="col">
                            <Form inline onSubmit={this.onSubmit} >
                                <InputGroup>
                                    <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{ width: 400 }} />
                                    <InputGroupAddon addonType="append">
                                        <Button size="sm" color="success" onClick={this.onBntBuscarClick}> Buscar <img src="/imagenes/zoom.png" /> </Button>
                                    </InputGroupAddon>
                                    &nbsp;
                                    &nbsp;
                                    <Button size="sm" color="success" outline onClick={this.nuevo}><img src="/imagenes/add.png" /></Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button size="sm" color="success" outline onClick={this.imprimir}><img src="/imagenes/printer.png" /></Button>
                                    &nbsp;
                                    <Button size="sm" color="success" outline onClick={this.excel}><img src="/imagenes/excel.png" /></Button>
                                </InputGroup>
                            </Form>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <ObjetoTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargar} />
                </div>
                <ObjetoForm urlws={this.props.urlws} visible={this.state.formVisible} objeto={this.state.objeto} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar} />
            </Container>
        );
    }
}

class ObjetoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Id</th>
                        <th>Nombre</th>
                        <th style={{width:60, textAlign:'center'}}>Activo</th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((o, i) => (
                        <ObjetoRow key={i} objeto={o} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjetoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.objeto.I);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td>{objeto.I}</td>
            <td>{objeto.N}</td>
            <td style={{textAlign:'center'}}><input type="checkbox" checked={objeto.A}/></td>
            <td>
                <img src="/imagenes/edit.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
        </tr>
        );
    }
}

class ObjetoForm extends React.Component {
    constructor(props){
        super(props);
        let obj = props.objeto;
        obj.PwdB = obj.Pwd;
        this.state = {
            objeto:obj,
            cargando:false,
            mostrarDialogo:false,
            recargarAcst:false,
            mostrarDialogoCte:false,
            recargarCtes:false,
            acts:[],
            ctes:[]
        };
    }
    componentWillReceiveProps(newProps){
        let obj = newProps.objeto;
        obj.PwdB = obj.Pwd;
        this.setState({
            objeto:obj,
            cargando:false,
            mostrarDialogo:false,
            recargarAcst:false,
            mostrarDialogoCte:false,
            recargarCtes:false,
            acts:[],
            ctes:[]
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const obj = {
            ...this.state.objeto,
            [campo] : e.target.value
        };
        this.setState({objeto:obj});
    }
    onChecked = (e) => {
        const campo = e.target.name;
        const obj = {
            ...this.state.objeto,
            [campo] : e.target.checked
        };
        this.setState({objeto:obj});
    }
    cargarActividades = async() =>{
        const usu = this.state.objeto.Id;
        this.setState({cargando:true});
        const url = `${this.props.urlws}/actividad/listaUsuario/${usu}?todas=N`;
        const resp = await fetch(url);
        this.state.objeto.Actividades = await resp.json();
        this.setState({cargando:false});
    }
    cargarClientes = async() =>{
        const usu = this.state.objeto.Id;
        this.setState({cargando:true});
        const url = `${this.props.urlws}/usuario/listaClientes/${usu}?todos=N`;
        const resp = await fetch(url);
        this.state.objeto.Clientes = await resp.json();
        this.setState({cargando:false});
    }
    onSel = async(i) => {
        const usu = this.state.objeto.Id;
        const act = this.state.acts[i].I;
        const sta = !this.state.acts[i].A;
        const val = sta ? "S":"N";
        this.setState({cargando:true});
        const url = `${this.props.urlws}/usuario/guardarAct/${usu}?act=${act}&val=${val}`;
        const resp = await fetch(url);
        const res = await resp.text();
        if(res.substring(0,3) !== "Err"){
            this.state.acts[i].A = sta;
            this.state.recargarAcst = true;
            this.setState({cargando:false});
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
        }
    }
    onSelCte = async(i) => {
        const usu = this.state.objeto.Id;
        const cte = this.state.ctes[i].I;
        const sta = !this.state.ctes[i].A;
        const val = sta ? "S":"N";
        this.setState({cargando:true});
        const url = `${this.props.urlws}/usuario/guardarCte/${usu}?cte=${cte}&val=${val}`;
        const resp = await fetch(url);
        const res = await resp.text();
        if(res.substring(0,3) !== "Err"){
            this.state.ctes[i].A = sta;
            this.state.recargarCtes = true;
            this.setState({cargando:false});
        }
        else{
            this.setState({cargando:false});
            Swal.fire('Atencion',res,'error');
        }
    }
    guardar = () =>{
        if(this.state.objeto.Pwd.length == 0){
            Swal.fire('Atencion','Especifique la contraseña','success');
            return;
        }
        if(this.state.objeto.Pwd != this.state.objeto.PwdB){
            Swal.fire('Atencion','La contraseña no coincide','success');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB(this.state.objeto);
            }
          })        
    }
    guardarB = async(dato) =>{
        const url = this.props.urlws +"/usuario/wsGuardar";
        this.setState({cargando:true});
        if(dato.Codigo === 0)
           dato.Codigo = '';
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res.substring(0,3) !== "Err"){
            Swal.fire('Atencion','Datos guardados','success');
            this.props.guardar();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    editarActs = async () =>{
        this.setState({cargando:true, recargarAcst:false});
        const url = `${this.props.urlws}/actividad/listaCliente/${this.state.objeto.Id}?todas=S`;
        const resp = await fetch(url);
        const acts = await resp.json();
        this.setState({mostrarDialogo:true, cargando:false, acts});
    }
    editarCtes = async () =>{
        this.setState({cargando:true, recargarCtes:false});
        const url = `${this.props.urlws}/usuario/listaClientes/${this.state.objeto.Id}?todos=S`;
        const resp = await fetch(url);
        const ctes = await resp.json();
        this.setState({mostrarDialogoCte:true, cargando:false, ctes});
    }
    handleClose = () =>{
        if(this.state.recargarAcst)
           this.cargarActividades();
        this.setState({mostrarDialogo:false, recargarAcst:false});
    }
    handleCloseCte = () =>{
        if(this.state.recargarCtes)
           this.cargarClientes();
        this.setState({mostrarDialogoCte:false, recargarCtes:false});
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.objeto === undefined)
            return null;
        if(Object.keys(this.props.objeto).length === 0)
            return null;
        if(this.props.objeto.Actividades === undefined){
            this.props.objeto.Actividades = [];
            this.props.objeto.Clientes = [];
        }
        let id = this.state.objeto.Id;
        if(id == 0)
           id = "NUEVO";
        return (
            <Container style={estilo}>
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <Form>
                    <div className="row">
                        <div className="col-1">
                            <FormGroup >
                                <span>Id</span>
                                <Input size="sm" type="text" name="Codigo" value={id} />
                            </FormGroup>
                        </div>
                        {' '}
                        <div className="col-10">
                            <FormGroup>
                                <span>Nombre</span>
                                <Input size="sm" type="text" name="Nombre" value={this.state.objeto.Nombre} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <span>Contraseña</span><br />
                            <Input size="sm" type="password" name="Pwd" value={this.state.objeto.Pwd} onChange={this.onChange} />
                        </div>
                        <div className="col-2">
                            <span>Contraseña</span><br />
                            <Input size="sm" type="password" name="PwdB" value={this.state.objeto.PwdB} onChange={this.onChange} />
                        </div>
                        <div className="col-2">
                            <span>Activo</span><br />
                            <input type="checkbox" name="Activo" checked={this.state.objeto.Activo} onChange={this.onChecked} />
                        </div>
                        <div className="col-2">
                            <span>Administrador</span><br />
                            <input type="checkbox" name="Admin" checked={this.state.objeto.Admin} onChange={this.onChecked} />
                        </div>
                    </div>
                </Form>
                <br />
                <div className="row">
                    <div className="col">
                        <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button size="sm" color="danger" onClick={this.props.onClose}>Cancelar</Button>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-6">
                        <h6>Actividades &nbsp;&nbsp;&nbsp;&nbsp;<Button size="sm" color="warning" onClick={this.editarActs}> Editar <img src="/imagenes/edit.png" /> </Button> </h6>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                <th style={{ width: 250 }}>Servicio</th>
                                    <th>Actividad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.objeto.Actividades.map((o, i) => (
                                    <tr>
                                        <tr style={{ width: 250 }}>{o.M}</tr>
                                        <td>{o.N}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="col-6">
                        <h6>Clientes &nbsp;&nbsp;&nbsp;&nbsp;<Button size="sm" color="warning" onClick={this.editarCtes}> Editar <img src="/imagenes/edit.png" /> </Button> </h6>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.objeto.Clientes.map((o, i) => (
                                    <tr>
                                        <td>{o.N}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Modal size="lg" id="dlgActs" name="dlgActs" isOpen={this.state.mostrarDialogo} toggle={this.handleClose}>
                    <ModalBody>
                        <h5>Actividades</h5>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                <th style={{ width: 300 }}>Servicio</th>
                                <th>Actividad</th>
                                <th style={{width:30}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.acts.map((o, i) => (
                                    <tr>
                                        <tr style={{ width: 300 }}>{o.M}</tr>
                                        <td>{o.N}</td>
                                        <td style={{textAlign:'center'}}>
                                            <input type="checkbox" checked={o.A} onChange={()=> this.onSel(i)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal> 
                <Modal size="lg" id="dlgCtes" name="dlgCtes" isOpen={this.state.mostrarDialogoCte} toggle={this.handleCloseCte}>
                    <ModalBody>
                        <h5>Clientes</h5>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                <th>Cliente</th>
                                <th style={{width:30}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.ctes.map((o, i) => (
                                    <tr>
                                        <td>{o.N}</td>
                                        <td style={{textAlign:'center'}}>
                                            <input type="checkbox" checked={o.A} onChange={()=> this.onSelCte(i)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal> 
             </Container>
        );
    }
}