import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';

export default class Diario extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            empresa:props.usuario.empresa,
            mostrarDialogo:false,
            fecha:fec,
            cargando:false,
            actividad:0,
            cliente:0,
            horaIni:'00',
            horaFin:'00',
            minIni:'00',
            minFin:'00',
            tipo:'O',
            comenta:'',
            acts:[],
            ctes:[],
            movs:[],
            horas:[],
            minutos:[],
            tipos:[],
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/usuario/listaClientes/${this.props.usuario.id}?todos=N`;
        let resp = await fetch(url);
        const ctes = await resp.json();
        url = `${this.props.urlws}/actividad/listaUsuario/${this.props.usuario.id}?todas=N`;
        resp = await fetch(url);
        const acts = await resp.json();
        url = `${this.props.urlws}/empresa/horas/${this.state.empresa}`;
        resp = await fetch(url);
        const horas = await resp.json();
        url = `${this.props.urlws}/empresa/minutos/${this.state.empresa}`;
        resp = await fetch(url);
        const minutos = await resp.json();
        url = `${this.props.urlws}/diario/tipos`;
        resp = await fetch(url);
        const tipos = await resp.json();
        this.setState({cargando:false, ctes, acts, horas, minutos, tipos },()=>this.cargarMovs());
    }
    cargarMovs = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/diario/cargarUsuario/${this.props.usuario.id}?fecha=${this.state.fecha}`;
        let resp = await fetch(url);
        const movs = await resp.json();
        this.setState({cargando:false, movs });
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        if(campo == "horaIni"){
            let {horaFin} = this.state;
            if(horaFin < valor)
               horaFin = valor;
            this.setState({ "horaIni" : valor, horaFin});
        }
        else
           this.setState({ [campo] : valor});
    }
    handleClose = () =>{
        this.setState({mostrarDialogo:false});
    }
    nuevoMov =() =>{
        this.setState({mostrarDialogo:true});
    }
    guardar = () =>{
        if(this.state.cliente == 0 || this.state.actividad == 0){
            Swal.fire('Atencion','Especifique el cliente y la actividad','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.guardarB();
            }
          })        
    }
    guardarB = async() =>{
        const url = this.props.urlws +"/diario/wsGuardar";
        const dato = {
            Id:0,
            Fecha:this.state.fecha,
            Empresa:this.state.empresa,
            Usuario:this.props.usuario.id,
            Cliente:this.state.cliente,
            Actividad:this.state.actividad,
            Tipo:this.state.tipo,
            HoraIni:this.state.horaIni,
            HoraFin:this.state.horaFin,
            MinIni:this.state.minIni,
            MinFin:this.state.minFin,
            Texto:this.state.comenta
        }
        const json = JSON.stringify(dato);
        this.setState({cargando:true});
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.json();
        this.setState({cargando:false});
        if(res.Resp.substring(0,3) !== "Err"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({mostrarDialogo:false, movs:res.Lst});
        }
        else
            Swal.fire('Atencion',res.Resp,'error');
    }
    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}><img src="/imagenes/ok.png" alt="cargar" title="cargar"/></Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button color='success' size="sm" onClick={this.nuevoMov}><img src="/imagenes/add.png" alt="nuevo" title="nuevo"/></Button>
                                <span>&nbsp;&nbsp;</span>
                                <Button color='warning' size="sm" ><img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/></Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.movs} height={500} onRowClick={this.cargarDetalle} imprimir={this.imprimir} borrar={this.borrar} />
                </div>                
                <Modal id="dlgRango" name="dlgRango" isOpen={this.state.mostrarDialogo} toggle={this.handleClose}>
                    <ModalBody>
                        <h5>Agregar actividad</h5> 
                        <div className="row">
                            <div className="col">
                                <span>Actividad</span>
                                <Input size="sm" type="select" name="actividad" value={this.state.actividad} onChange={this.onChange}>
                                    <option key="act" value={0}>Especifique</option>
                                    {this.state.acts.map((i, k) =>
                                        <option key={"act" + k} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span>Cliente</span>
                                <Input size="sm" type="select" name="cliente" value={this.state.cliente} onChange={this.onChange}>
                                    <option key="cte0" value={0}>Especifique</option>
                                    {this.state.ctes.map((i, k) =>
                                        <option key={"cte"+ k} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                Inicio<br/>
                                <table>
                                    <tr>
                                        <td>
                                            <Input size="sm" type="select" name="horaIni" value={this.state.horaIni} onChange={this.onChange}>
                                                {this.state.horas.map((i, k) =>
                                                    <option key={"hra" + k} value={i}>{i}</option>
                                                )}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" type="select" name="minIni" value={this.state.minIni} onChange={this.onChange}>
                                                {this.state.minutos.map((i, k) =>
                                                    <option key={"min" + k} value={i}>{i}</option>
                                                )}
                                            </Input>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-4">
                                Fin<br/>
                                <table>
                                    <tr>
                                        <td>
                                            <Input size="sm" type="select" name="horaFin" value={this.state.horaFin} onChange={this.onChange}>
                                                {this.state.horas.map((i, k) =>
                                                    <option key={"hra" + k} value={i}>{i}</option>
                                                )}
                                            </Input>
                                        </td>
                                        <td>
                                            <Input size="sm" type="select" name="minFin" value={this.state.minFin} onChange={this.onChange}>
                                                {this.state.minutos.map((i, k) =>
                                                    <option key={"min" + k} value={i}>{i}</option>
                                                )}
                                            </Input>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col">
                                <span>Tipo</span>
                                <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange}>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={"tp" + k} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <span>Comentario</span>
                                <textarea class="form-control" rows="5" name="comenta" value={this.state.comenta} onChange={this.onChange}></textarea>
                            </div>
                        </div>                        
                        <div className="row">
                            <div className="col">
                                <br/>
                                <Button size="sm" color="success" onClick={this.guardar}>Aceptar</Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button size="sm" color="danger" onClick={this.handleClose}>Cancelar</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        alert("pendiente");// this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Actividad</th>
                        <th>Tipo</th>
                        <th style={{width:300}}>Cliente</th>
                        <th style={{width:40}}>Inicio</th>
                        <th style={{width:40}}>Fin</th>
                        <th style={{width:40}}>Tiempo</th>
                        <th style={{width:40}}>Perd.</th>
                        <th style={{width:200}}>Comentarios</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} borrar={this.props.borrar} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    borrar = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar la actividad " + this.props.mov.A + " de " + this.props.mov.C + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                alert("pendiente");// this.props.borrar(this.props.mov);
            }
        });
    }
    render(){
        let {mov} = this.props;
        return (
            <tr>
                <td>{mov.A}</td>
                <td>{mov.T}</td>
                <td style={{ width: 300 }}>{mov.C}</td>
                <td style={{ width: 40 }}>{mov.I}</td>
                <td style={{ width: 40 }}>{mov.F}</td>
                <td style={{ width: 40 }}>{mov.D}</td>
                <td style={{ width: 40 }}>{mov.P}</td>
                <td style={{ width: 200 }}>{mov.X}</td>
                <td>
                    <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick} />
                </td>
                <td>
                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir} />
                </td>
                <td>
                    <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.borrar} />
                </td>
            </tr>
        );
    }
}