import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';

export default class DiarioUsu extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            empresa:props.usuario.empresa,
            mostrarDialogo:false,
            fechaIni:fec,
            fechaFin:fec,
            cargando:false,
            actividad:0,
            usuario:0,
            horaIni:'00',
            horaFin:'00',
            minIni:'00',
            minFin:'00',
            tipo:'O',
            comenta:'',
            usuario:0,
            acts:[],
            usuarios:[],
            movs:[],
            horas:[],
            minutos:[],
            tipos:[],
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/usuario/lista/${this.state.empresa}`;
        let resp = await fetch(url);
        const usuarios = await resp.json();
        this.setState({cargando:false, usuarios });
    }
    cargarMovs = async () => {
        if(this.state.usuario == 0 ){
            Swal.fire('Atencion','Especifique el usuario','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/usuario/diario/${this.state.usuario}?fechaIni=${this.state.fechaIni}&fechaFin=${this.state.fechaFin}`;
        let resp = await fetch(url);
        const movs = await resp.json();
        this.setState({cargando:false, movs });
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    handleClose = () =>{
        this.setState({mostrarDialogo:false});
    }
    render () {
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-6">
                                <span>Usuario</span>
                                <Input size="sm" type="select" name="usuario" value={this.state.usuario} onChange={this.onChange}>
                                    <option key="cte0" value={0}>Especifique</option>
                                    {this.state.usuarios.map((i, k) =>
                                        <option key={"cte"+ k} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}><img src="/imagenes/ok.png" alt="cargar" title="cargar"/></Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button color='warning' size="sm" ><img src="/imagenes/printer.png" alt="imprimir" title="imprimir"/></Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.movs} height={500} onRowClick={this.cargarDetalle} imprimir={this.imprimir} borrar={this.borrar} />
                </div>                
            </Container>
        )
    }
}

class MovsTable extends React.Component {
    onRowClick = (cve) => {
        alert("pendiente");// this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Actividad</th>
                        <th>Tipo</th>
                        <th style={{width:300}}>Cliente</th>
                        <th style={{width:40}}>Inicio</th>
                        <th style={{width:40}}>Fin</th>
                        <th style={{width:40}}>Tiempo</th>
                        <th style={{width:200}}>Comentarios</th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} borrar={this.props.borrar} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    borrar = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar la actividad " + this.props.mov.A + " de " + this.props.mov.C + " ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                alert("pendiente");// this.props.borrar(this.props.mov);
            }
        });
    }
    render(){
        let {mov} = this.props;
        return (
            <tr>
                <td>{mov.A}</td>
                <td>{mov.T}</td>
                <td style={{ width: 300 }}>{mov.C}</td>
                <td style={{ width: 40 }}>{mov.I}</td>
                <td style={{ width: 40 }}>{mov.F}</td>
                <td style={{ width: 40 }}>{mov.D}</td>
                <td style={{ width: 200 }}>{mov.X}</td>
            </tr>
        );
    }
}